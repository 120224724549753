import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { EditableImageComponent } from './editable-image.component';
import { EditableImageService } from './editable-image.service';

@NgModule({
	imports: [FuseSharedModule],
	exports: [EditableImageComponent],
	declarations: [EditableImageComponent],
	providers: [
		EditableImageService
	],
})
export class EditableImageModule {
}
