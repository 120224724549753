import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import firebase from '@src/worker/firebase';
import { Observable } from 'rxjs';
import { FirebaseAdminService, FirebaseOwnerService, FirebaseWorkerService } from '~/@remodela/services/firebase.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private _router: Router,
		private _adminFirebase: FirebaseAdminService,
		private _ownerFirebase: FirebaseOwnerService,
		private _workerFirebase: FirebaseWorkerService,
	) { }

	canActivate(
		next: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> {
		return new Observable((observer) => {
			this._adminFirebase.auth.onAuthStateChanged(async (auth) => {
				if (!auth) {
					return observer.next(this._router.parseUrl('auth/login'))
				}
				this._ownerFirebase.auth.onAuthStateChanged(async (auth) => {
					if (!auth) {
						return observer.next(this._router.parseUrl('auth/login'))
					}
				})
				this._workerFirebase.auth.onAuthStateChanged(async (auth) => {
					if (!auth) {
						return observer.next(this._router.parseUrl('auth/login'))
					}
					firebase.auth().onAuthStateChanged(async (auth) => {
						if (!auth) {
							return observer.next(this._router.parseUrl('auth/login'))
						}
						return observer.next(true)
					})
				})
			})
		})
	}
}
