import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';

Sentry.init({
	dsn: 'https://e61d76f966ec4587abc5f5b42711a4c8@o328086.ingest.sentry.io/5427767',
	integrations: [
		new Integrations.BrowserTracing({
		tracingOrigins: ["https://d4c.remodela.jp/", "https://remodela-adm-furuel.web.app/"],
		routingInstrumentation: Sentry.routingInstrumentation,
		}),
	],
	tracesSampleRate: 1.0,
	enabled: environment.production
});

if (environment.production) {
	enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
	if (module['hot']) {
		hmrBootstrap(module, bootstrap);
	} else {
		console.error('HMR is not enabled for webpack-dev-server!');
		console.log('Are you using the --hmr flag for ng serve?');
	}
} else {
	bootstrap().catch(err => console.error(err));
}
