export const locale = {
  lang: 'en',
  data: {
    'NAV': {
      'USERS': {
        'TITLE': 'Users',
        'OWNERS': 'Owners',
        'CRAFTMANS': 'Craftmans',
      },
      'PROJECTS': {
        'TITLE': 'Projects',
        'PROJECTS': 'Projects',
      },
      'MASTERS': {
        'TITLE': 'Masters',
        'MATERIALS': 'Materials',
        'MAKERS': 'Makers',
        'TYPE_NAMES': 'Type names',
      }
    }
  }
};
