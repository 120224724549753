import { Injectable } from '@angular/core';
import { FirebaseWorkerService } from '@services/firebase.service';
import { DBDocPath } from '@shared/shared.constants';
import { Storage } from '@shared/storage';

@Injectable()
export class EditableImageService {
	storage: Storage

	constructor(
		private _workerFirebase: FirebaseWorkerService,
	) {
		this.storage = new Storage(this._workerFirebase.storage)
	}
	async uploadImg(projectId: string, detailId: string, image: string): Promise<string> {
		if (!image) {
			return null;
		}

		const path = DBDocPath.Worker.reworkDetail(projectId, detailId);
		if (!image) {
			return null;
		}

		return await this.storage.uploadCompressedImg(image, path)
	}
}
