export const locale = {
	lang: 'ja',
	data: {
		'NAV': {
			'USERS': {
				'TITLE': 'ユーザ',
				'OWNERS': '施主ユーザ',
				'CRAFTMANS': '職人ユーザ',
			},
			'PROJECTS': {
				'TITLE': '案件',
				'PROJECTS': '案件',
			},
			'MASTERS': {
				'TITLE': 'マスタ',
				'MATERIALS': '資材',
				'CLEANINGS': '資材',
			}
		}
	}
};
