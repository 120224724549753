import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { DBName } from "@shared/shared.constants";
import "firebase/analytics";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";
import "firebase/remote-config";
import "firebase/storage";

export class FirebaseService {
	private firebase: firebase.app.App;

	constructor(options: Object, name?: string) {
		if (this.firebase) {
			return;
		}
		this.firebase = firebase.initializeApp(options, name);
	}

	get firestore(): firebase.firestore.Firestore {
		return this.firebase.firestore();
	}

	get storage(): firebase.storage.Storage {
		return this.firebase.storage();
	}

	// ownerのみ
	get lifecycleDeletemiddleStorage(): firebase.storage.Storage {
		return this.firebase.storage(
			// @ts-ignore
			environment.firebase.Owner.storageBucketLifecycleDeleteMiddle
		);
	}

	get auth(): firebase.auth.Auth {
		return this.firebase.auth();
	}

	get functions(): firebase.functions.Functions {
		return this.firebase.functions("asia-northeast1");
	}

	get currentUserId(): string {
		return this.firebase.auth()?.currentUser?.uid;
	}

	createDocId(): string {
		return this.firestore.collection("_").doc().id;
	}

	async getIdToken(): Promise<string> {
		console;
		return await this.firebase.auth().currentUser.getIdToken();
	}
}

@Injectable()
export class FirebaseAdminService extends FirebaseService {
	constructor() {
		super(environment.firebase.Admin, DBName.Admin);
	}
}

@Injectable()
export class FirebaseOwnerService extends FirebaseService {
	constructor() {
		super(environment.firebase.Owner, DBName.Owner);
	}
}

@Injectable()
export class FirebaseWorkerService extends FirebaseService {
	constructor() {
		super(environment.firebase.Worker, DBName.Worker);
	}
}
