import 'firebase/analytics'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/messaging'
import 'firebase/storage'
import { environment } from '../environments/environment';
// import 'firebase/remote-config'
// import dotenv from 'dotenv'

console.log(1)

// const envPath = `./configs/.env.${process.env.ENV || 'development'}`
// dotenv.config({ path: envPath })

// if (!firebase.apps.length) {
firebase.initializeApp(environment.firebase.Worker)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
// }

// console.log(process.env)
console.log(2)

export default firebase
