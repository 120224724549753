import FirebaseFirestore from '@google-cloud/firestore';
import firebase from 'firebase/app';
import momentTz from 'moment-timezone';
import { TimeZone, Kanto, Kinki, KansaiConfig, KantoConfig } from './shared.constants'
import Big from 'big.js'

export class MathUtils {
	static roundDown(num: number): number {
		const mulNum = Math.trunc(Number(Big(num).mul(100)))
		return Number(Big(mulNum).div(100))
	}
}

export class DateUtils {
	/**
	* @param base 基準日
	* @param afterDay 何営業日後
	* @param holidays 休日のコレクション
	* @return 日付
	*/
	static calcBusinessDate(base: Date, afterDay: number, holidays: Set<string>): Date {
		const d = new Date(base)
		for (let i = 0; i < afterDay; i++) {
			d.setDate(d.getDate() + 1);
			if (holidays.has(momentTz(d).format('YYYY/MM/DD'))) {
				i--;
			}
		}
		return d;
	}

	static beforeBusinessDate(base: Date, holidays: Set<string>): Date {
		const d = new Date(base)
		while (holidays.has(momentTz(d).format('YYYY/MM/DD'))) {
			d.setDate(d.getDate() - 1);
		}
		return d;
	}

	/**
	* 基準日の次の営業日を返します。（10/3(日)の場合は10/4(月)を返す）
	* @param base 基準日
	* @param holidays 休日のコレクション
	* @return 次の営業日
	*/
	static nextBusinessDate(base: Date, holidays: Set<string>): Date {
		const d = new Date(base)
		while (holidays.has(momentTz(d).format('YYYY/MM/DD'))) {
			d.setDate(d.getDate() + 1);
		}
		return d;
	}

	/**
	* 基準日からX営業期間を取得
	* @param base 基準日
	* @param businessDays 営業日数
	* @param holidays 休日のコレクション
	* @return DBModel.Owner.Project.Term
	*/
	static buildTermWithBusinessDay(base: Date, businessDays: number, holidays: Set<string>): DBModel.Owner.Project.Term {
		if (businessDays <= 0) {
			businessDays = 1;
		}
		const nextStartDate = this.nextBusinessDate(base, holidays)
		return {
			startDate: nextStartDate,
			endDate: this.calcBusinessDate(nextStartDate, businessDays-1, holidays),
			days: businessDays,
		}
	}

	static toDate(timestamp: Date | firebase.firestore.Timestamp | FirebaseFirestore.Timestamp): Date {
		if (timestamp instanceof Date) {
			return timestamp
		} else {
			return momentTz(timestamp.toDate()).tz(TimeZone).toDate()
		}
	}
}

export class ModelUtils {
	static toModelFromDoc<T extends DBModel.Base>(snapshot: firebase.firestore.DocumentSnapshot | FirebaseFirestore.DocumentData): T {
		if (!snapshot.exists) {
			return {} as T
		}
		const model = snapshot.data() as T;
		model.id = snapshot.id;
		return model;
	}

	static setCreateInfo<T extends DBModel.Base>(model: T, uid: string, timestamp: FireTimestamp): T {
		model.createdAt = timestamp;
		model.updatedAt = timestamp;
		model.createdUid = uid;
		model.updatedUid = uid;
		return model;
	}

	static setUpdateInfo<T extends DBModel.Base>(model: T, uid: string, timestamp: FireTimestamp): T {
		delete model['createdAt'];
		delete model['createdUid'];
		model.updatedAt = timestamp;
		model.updatedUid = uid;
		return model;
	}

	/**
	 * IDを除外したモデル項目をオブジェクト形式に変換して返す
	 * @param model {T}
	 * @returns {T}
	 */
	static getDataWithoutId<T extends DBModel.Base>(model: T): T {
		const data: { [key: string]: any } = {};
		Object.keys(model).map((key: string) => {
			if ('id' !== key) {
				data[key] = model[key];
			}
		});
		return data as T;
	}

	static copyProps<T extends DBModel.Base>(from: any, to: any): T {
		const copied: any = {};
		for (const prop in from) {
			if (!to.hasOwnProperty(prop)) {
				continue;
			}
			copied[prop] = from[prop];
		}
		return copied as T;
	}
}
　　　　　　
export class PrefecturesUtils {
	static isKanto(state: string): boolean {
		return Kanto.includes(state);
	}
	static isKinki(state: string): boolean {
		return Kinki.includes(state);
	}
	static getStates(state: string): string[] {
		if (this.isKanto(state)) {
			return Kanto
		} else if (this.isKinki(state)) {
			return Kinki
		}
		return []
	}
	static getStatesByAddress(address: string): string[] {
		const state = address.replace(/^(.{2}[都道府県]|.{3}県)(.+)/, "$1 $2").split(' ')[0];
		return PrefecturesUtils.getStates(state);
	}
  static getConfig(state: string) {
    if (PrefecturesUtils.isKanto(state)) {
      return KantoConfig
    }
    return KansaiConfig
  }
}

