import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from '@components/auth/auth.guard';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { Error404Component } from '@components/errors/404/error-404.component';
import { Error404Module } from '@components/errors/404/error-404.module';
import { environment } from '@env/environment';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { HotTableModule } from '@handsontable/angular';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { DBName } from '@shared/shared.constants';
import { RemodelaSharedModule } from '@src/@remodela/shared.module';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppStoreModule } from 'app/store/store.module';
import 'hammerjs';
import { ToastrModule } from 'ngx-toastr';
import {
	FirebaseAdminService,
	FirebaseOwnerService,
	FirebaseWorkerService
} from '~/@remodela/services/firebase.service';

const appRoutes: Routes = [
	{
		path: '',
		component: DashboardComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'auth',
		loadChildren: () => import('@components/auth/auth.module').then(m => m.AuthModule),
	},
	{
		path: 'owners',
		loadChildren: () => import('@components/owners/owners.module').then(m => m.OwnersModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'workers',
		loadChildren: () => import('@components/workers/workers.module').then(m => m.WorkersModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'projects',
		loadChildren: () => import('@components/owners/projects/projects.module').then(m => m.ProjectsModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'prices',
		loadChildren: () => import('@components/prices/prices.module').then(m => m.PricesModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'notifications',
		loadChildren: () => import('@components/notifications/notifications.module').then(m => m.NotificationsModule),
		canActivate: [AuthGuard],
	},
	{
		path: '**',
		component: Error404Component
	},
];

@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
		ToastrModule.forRoot({
			disableTimeOut: true,
			positionClass: 'toast-top-right',
		}),

		TranslateModule.forRoot(),

		// Pages
		Error404Module,

		// Material moment date module
		MatMomentDateModule,

		// Material
		MatButtonModule,
		MatIconModule,

		// Fuse modules
		FuseModule.forRoot(fuseConfig),
		FuseProgressBarModule,
		FuseSharedModule,
		FuseSidebarModule,
		FuseThemeOptionsModule,

		// Firebase modules
		AngularFireModule.initializeApp(environment.firebase.Admin, DBName.Admin),
		AngularFireModule.initializeApp(environment.firebase.Owner, DBName.Owner),
		AngularFireModule.initializeApp(environment.firebase.Worker, DBName.Worker),
		AngularFirestoreModule,
		AngularFireAuthModule,
		AngularFireStorageModule,
		AngularFireFunctionsModule,

		// Handsontable
		HotTableModule,

		// Remodela modules
		RemodelaSharedModule,

		// App modules
		LayoutModule,
		AppStoreModule,
	],
	bootstrap: [
		AppComponent
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
			useValue: undefined
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { },
			deps: [Sentry.TraceService],
			multi: true,
		},
		FirebaseAdminService,
		FirebaseOwnerService,
		FirebaseWorkerService,
	]
})
export class AppModule {
}
