import { NgModule } from '@angular/core';

import { SafePipe } from '@pipes/safe-pipe';
import { IncludeTaxPipe } from '@pipes/include-tax-pipe';
import { JobTypePipe } from '@src/@remodela/pipes/job-type-pipe';
import { TimestampPipe } from '@pipes/timestamp-pipe';
import { WorkerStatusPipe } from '@pipes/worker-status-pipe';

import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';

import { RemodelaDirectivesModule } from '@directives/directives';
import { EditableImageModule } from '@remodela/components/shared/editable-image/editable-image.module';

@NgModule({
	declarations: [
		SafePipe,
		IncludeTaxPipe,
		TimestampPipe,
		JobTypePipe,
		WorkerStatusPipe,
	],
	imports: [
		MatProgressSpinnerModule,
		OverlayModule,
		RemodelaDirectivesModule,
	],
	exports: [
		RemodelaDirectivesModule,
		EditableImageModule,
		SafePipe,
		IncludeTaxPipe,
		TimestampPipe,
		JobTypePipe,
		WorkerStatusPipe,
	],
	providers: [
		TimestampPipe,
	],
	entryComponents: [
		MatSpinner
	],
})
export class RemodelaSharedModule {
}
