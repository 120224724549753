import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
	{
		id: 'owners',
		title: '施主',
		translate: 'NAV.USERS.TITLE',
		type: 'group',
		children: [
			{
				id: 'projects',
				title: '案件',
				translate: 'NAV.PROJECTS.TITLE',
				type: 'item',
				icon: 'person',
				url: 'owners/projects/'
			},
			{
				id: 'owners',
				title: '企業',
				translate: 'NAV.USERS.OWNERS',
				type: 'item',
				icon: 'person',
				url: 'owners/companies/'
			},
			{
				id: 'owners',
				title: 'ユーザ',
				translate: 'NAV.USERS.OWNERS',
				type: 'item',
				icon: 'person',
				url: 'owners/users/'
			},
		]
	},
	{
		id: 'workers',
		title: '職人',
		translate: 'NAV.USERS.TITLE',
		type: 'group',
		children: [
			{
				id: 'workers',
				title: 'ユーザ',
				translate: 'NAV.USERS.CRAFTMANS',
				type: 'item',
				icon: 'person',
				url: 'workers/users'
			},
		]
	},
	{
		id: 'prices',
		title: 'マスタ',
		translate: 'NAV.USERS.TITLE',
		type: 'group',
		children: [
			{
				id: 'materials',
				title: '資材',
				translate: 'NAV.USERS.OWNERS',
				type: 'item',
				icon: 'person',
				url: 'prices/materials/'
			},
			{
				id: 'notifications',
				title: 'お知らせ',
				translate: 'NAV.USERS.OWNERS',
				type: 'item',
				icon: 'person',
				url: 'notifications/'
			},
		]
	}
];
