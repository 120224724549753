import { NgModule } from '@angular/core';

@NgModule({
	declarations: [
	],
	imports: [],
	exports: [
	]
})
export class RemodelaDirectivesModule {
}
