import { PrefecturesUtils } from "./utils";

/** FirestoreのDB名 */
export const DBName = {
  Admin: "admin",
  Owner: "owner",
  Worker: "worker",
  Remopad: "remopad",
};

// 検索条件
type SearchType = "projects";

/** Firestoreのコレクショングループ */
export const DBCollectionGroup = {
  // システム
  Shared: {
    shared: "shared",
  },
  // 施主
  Owner: {
    /** 管理者ユーザ */
    adminUsers: "adminUsers",
    /** 事前登録ユーザ */
    preOwners: "_preOwners",
    /** 企業（隠しデータ） */
    hiddenCompanies: "_companies",
    /** 企業 */
    companies: "companies",
    /** Biz工事業者 */
    bizContractors: "bizContractors",
    /** biz見積 */
    bizEstimates: "bizEstimates",
    /** bizユーザ */
    bizUsers: "bizUsers",
    /** ユーザ */
    users: "users",
    /** ユーザ */
    _users: "_users",
    /** ユーザ検索条件 */
    searchConditions: "searchConditions",
    /** やることリスト */
    tasks: "tasks",
    /** お知らせ */
    notifications: "notifications",
    /** 設定 */
    settings: "settings",
    /** 案件 */
    projects: "projects",
    /** 依頼 */
    requests: "requests",
    /** 工事業者 */
    contractors: "contractors",
    /** 建物 */
    buildings: "buildings",
    /** レビュー */
    reviews: "reviews",
    /** 見積 */
    estimates: "estimates",
    /** オーナー提案 */
    ownerProposals: "ownerProposals",
    /** オーナー提案デフォルト単価 */
    ownerProposalDefaultUnitPrices: "ownerProposalDefaultUnitPrices",
    /** 資材 */
    materials: "materials",
    /** メッセージ */
    messages: "messages",
    /** 請求 */
    bills: "bills",
    /** 取下げ理由アンケート */
    closeReasonQuestionnaires: "closeReasonQuestionnaires",
    /** 通知メール受信者 */
    recipients: "recipients",
    /** アドオン機能のマスタ*/
    addOnMasters: "addOnMasters",
    /** アドオン機能*/
    addOns: "addOns",
  },
  // 職人
  Worker: {
    /** 管理者ユーザ */
    adminUsers: "adminUsers",
    /** ユーザ */
    users: "users",
    /** ユーザ（運営用） */
    _users: "_users",
    /** 作業履歴 */
    workingHistory: "workingHistory",
    /** お知らせ */
    notification: "notification",
    /** 作業回数 */
    workCounts: "workCounts",
    /** 案件 */
    projects: "projects",
    /** 案件 */
    _projects: "_projects",
    /** 評価 */
    rates: "rates",
    /** 作業内容 */
    works: "works",
    /** 再施工 */
    reworks: "reworks",
    /** 再施工（詳細） */
    reworkDetails: "reworkDetails",
    /** 資材 */
    materials: "materials",
    /** 職種 */
    _jobTypes: "_jobTypes",
  },
  // リモデラ事務
  Remopad: {
    /** ユーザ */
    users: "users",
    /** ユーザ（セキュア） */
    _users: "_users",
    /** レシート */
    receipts: "receipts",
    /** 紹介 */
    social: "social",
  },
};

/** Firestoreのコレクションパス */
export const DBCollectionPath = {
  // システム
  Shared: {
    shared: () => DBCollectionGroup.Shared.shared,
  },
  // 施主
  Owner: {
    /** 事前登録ユーザ */
    preOwners: () => DBCollectionGroup.Owner.preOwners,
    /** 企業（隠しデータ） */
    hiddenCompanies: () => DBCollectionGroup.Owner.hiddenCompanies,
    /** 企業 */
    companies: () => DBCollectionGroup.Owner.companies,
    /** biz工事業者 */
    bizContractors: () => DBCollectionGroup.Owner.bizContractors,
    /** ユーザ */
    bizUsers: (bizContractorId: string) =>
      `${DBDocPath.Owner.bizContractor(bizContractorId)}/${
        DBCollectionGroup.Owner.bizUsers
      }`,
    /** 企業の設定 */
    settings: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${
        DBCollectionGroup.Owner.settings
      }`,
    /** 未承認ユーザ */
    inactiveUsers: () =>
      `${DBDocPath.Owner.hiddenCompany()}/${DBCollectionGroup.Owner.users}`,
    /** ユーザ */
    users: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${DBCollectionGroup.Owner.users}`,
    /** ユーザ */
    _users: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${DBCollectionGroup.Owner._users}`,
    /** ユーザ検索条件 */
    searchConditions: (companyId: string, uid: string) =>
      `${DBDocPath.Owner.user(companyId, uid)}/${
        DBCollectionGroup.Owner.searchConditions
      }`,
    /** やること・ToDo */
    tasks: (companyId: string, uid: string) =>
      `${DBDocPath.Owner.user(companyId, uid)}/${
        DBCollectionGroup.Owner.tasks
      }`,
    /** お知らせ */
    notifications: (companyId: string, uid: string) =>
      `${DBDocPath.Owner.user(companyId, uid)}/${
        DBCollectionGroup.Owner.notifications
      }`,
    /** 案件 */
    projects: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${
        DBCollectionGroup.Owner.projects
      }`,
    /** 案件（非公開の内部情報） */
    _projects: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/_${
        DBCollectionGroup.Owner.projects
      }`,
    /** 依頼 */
    requests: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${
        DBCollectionGroup.Owner.requests
      }`,
    /** 工事業者 */
    contractors: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${
        DBCollectionGroup.Owner.contractors
      }`,
    /** 建物 */
    buildings: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${
        DBCollectionGroup.Owner.buildings
      }`,
    /** レビュー */
    projectReviews: (companyId: string, projectId: string) =>
      `${DBDocPath.Owner.project(companyId, projectId)}/${
        DBCollectionGroup.Owner.reviews
      }`,
    /** 見積 */
    estimates: (companyId: string, projectId: string) =>
      `${DBDocPath.Owner.project(companyId, projectId)}/${
        DBCollectionGroup.Owner.estimates
      }`,
    /** biz見積 */
    bizEstimates: (companyId: string, projectId: string) =>
      `${DBDocPath.Owner.project(companyId, projectId)}/${
        DBCollectionGroup.Owner.bizEstimates
      }`,
    /** オーナー提案 */
    ownerProposals: (companyId: string, projectId: string) =>
      `${DBDocPath.Owner.project(companyId, projectId)}/${
        DBCollectionGroup.Owner.ownerProposals
      }`,
    /** オーナー提案デフォルト単価 */
    ownerProposalDefaultUnitPrices: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${
        DBCollectionGroup.Owner.ownerProposalDefaultUnitPrices
      }`,
    /** 資材 */
    materials: () => DBCollectionGroup.Owner.materials,
    /** メッセージ */
    messages: (companyId: string, requestId: string) =>
      `${DBDocPath.Owner.request(companyId, requestId)}/${
        DBCollectionGroup.Owner.messages
      }`,
    /** 請求 */
    bills: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${DBCollectionGroup.Owner.bills}`,
    /** 取下げ理由アンケート */
    closeReasonQuestionnaires: (companyId: string, projectId: string) =>
      `${DBDocPath.Owner.project(companyId, projectId)}/${
        DBCollectionGroup.Owner.closeReasonQuestionnaires
      }`,
    /** 通知メール受信者 */
    recipients: (companyId: string) =>
      `${DBDocPath.Owner.companyNotificationSettings(companyId)}/${
        DBCollectionGroup.Owner.recipients
      }`,
    /** アドオンマスター */
    addOnMasters: () => DBCollectionGroup.Owner.addOnMasters,
    /** アドオン機能 */
    addOns: (companyId: string) =>
      `${DBDocPath.Owner.company(companyId)}/${DBCollectionGroup.Owner.addOns}`,
  },
  // 職人
  Worker: {
    /** ユーザ */
    users: () => DBCollectionGroup.Worker.users,
    /** ユーザ（運営用） */
    _users: () => DBCollectionGroup.Worker._users,
    /** 作業履歴 */
    workingHistory: (uid: string) =>
      `${DBDocPath.Worker.user(uid)}/${
        DBCollectionGroup.Worker.workingHistory
      }`,
    /** お知らせ */
    notification: (uid: string) =>
      `${DBDocPath.Worker.user(uid)}/${DBCollectionGroup.Worker.notification}`,
    /** 作業回数 */
    workCounts: () => DBCollectionGroup.Worker.workCounts,
    /** 案件 */
    projects: () => DBCollectionGroup.Worker.projects,
    /** 案件 */
    _projects: () => DBCollectionGroup.Worker._projects,
    /** 評価 */
    rates: () => DBCollectionGroup.Worker.rates,
    /** 作業内容 */
    works: (projectId: string) =>
      `${DBDocPath.Worker.project(projectId)}/${
        DBCollectionGroup.Worker.works
      }`,
    /** 再施工 */
    reworks: () => DBCollectionGroup.Worker.reworks,
    /** 再施工（詳細） */
    reworkDetails: (projectId: string) =>
      `${DBDocPath.Worker.rework(projectId)}/${
        DBCollectionGroup.Worker.reworkDetails
      }`,
    /** 資材 */
    materials: () => DBCollectionGroup.Worker.materials,
    /** 職種 */
    _jobTypes: () => DBCollectionGroup.Worker._jobTypes,
  },
  // リモデラ事務
  Remopad: {
    /** ユーザ */
    users: () => DBCollectionGroup.Remopad.users,
    /** ユーザ（セキュア） */
    _users: () => DBCollectionGroup.Remopad._users,
    /** レシート */
    receipts: (uid: string) =>
      `${DBDocPath.Remopad._user(uid)}/${DBCollectionGroup.Remopad.receipts}`,
    /** 紹介 */
    social: () => DBCollectionGroup.Remopad.social,
  },
};

/** Firestoreのドキュメントパス */
export const DBDocPath = {
  // システム
  Shared: {
    holidays: () => `${DBCollectionPath.Shared.shared()}/holidays`,
    projectNo: () => `${DBCollectionPath.Shared.shared()}/projectNo`,
    remodela: () => `${DBCollectionPath.Shared.shared()}/remodela`,
  },
  Owner: {
    /** 事前登録ユーザ */
    preOwners: (id: string) => `${DBCollectionPath.Owner.preOwners()}/${id}`,
    /** 企業（隠しデータ） */
    hiddenCompany: () => `${DBCollectionPath.Owner.hiddenCompanies()}/dummy`,
    /** 企業 */
    company: (companyId: string) =>
      `${DBCollectionPath.Owner.companies()}/${companyId}`,
    /** biz工事業者 */
    bizContractor: (bizContractorId: string) =>
      `${DBCollectionPath.Owner.bizContractors()}/${bizContractorId}`,
    /** bizユーザ */
    bizUser: (bizContractorId: string, uid: string) =>
      `${DBCollectionPath.Owner.bizUsers(bizContractorId)}/${uid}`,
    /** 企業の通知設定 */
    settings: (companyId: string, settingId: string) =>
      `${DBCollectionPath.Owner.settings(companyId)}/${settingId}`,
    /** 企業の通知に関する情報 */
    companyNotificationSettings: (companyId: string) =>
      `${DBCollectionPath.Owner.settings(companyId)}/notification`,
    /** 企業の非公開情報（管理者のみR/Wできる情報） */
    companyPrivateSettings: (companyId: string) =>
      `${DBCollectionPath.Owner.settings(companyId)}/private`,
    /** 未承認ユーザ */
    inactiveUser: (uid: string) =>
      `${DBCollectionPath.Owner.inactiveUsers()}/${uid}`,
    /** ユーザ */
    user: (companyId: string, uid: string) =>
      `${DBCollectionPath.Owner.users(companyId)}/${uid}`,
    /** ユーザ */
    _user: (companyId: string, uid: string) =>
      `${DBCollectionPath.Owner._users(companyId)}/${uid}`,
    /** 検索条件 */
    searchConditions: (
      companyId: string,
      uid: string,
      searchType: SearchType
    ) =>
      `${DBCollectionPath.Owner.searchConditions(
        companyId,
        uid
      )}/${searchType}`,
    /** タスク */
    tasks: (companyId: string, uid: string, taskId: string) =>
      `${DBCollectionPath.Owner.tasks(companyId, uid)}/${taskId}`,
    /** お知らせ */
    notifications: (companyId: string, uid: string, notificationId: string) =>
      `${DBCollectionPath.Owner.notifications(
        companyId,
        uid
      )}/${notificationId}`,
    /** 案件 */
    project: (companyId: string, id: string) =>
      `${DBCollectionPath.Owner.projects(companyId)}/${id}`,
    /** 依頼 */
    request: (companyId: string, id: string) =>
      `${DBCollectionPath.Owner.requests(companyId)}/${id}`,
    /** 工事業者 */
    contractor: (companyId: string, id: string) =>
      `${DBCollectionPath.Owner.contractors(companyId)}/${id}`,
    /** 建物 */
    building: (companyId: string, id: string) =>
      `${DBCollectionPath.Owner.buildings(companyId)}/${id}`,
    /** レビュー */
    projectReview: (companyId: string, projectId: string, id: string) =>
      `${DBCollectionPath.Owner.projectReviews(companyId, projectId)}/${id}`,
    /** 見積 */
    estimate: (companyId: string, projectId: string, id: string) =>
      `${DBCollectionPath.Owner.estimates(companyId, projectId)}/${id}`,
    /** biz見積 */
    bizEstimate: (companyId: string, projectId: string, id: string) =>
      `${DBCollectionPath.Owner.bizEstimates(companyId, projectId)}/${id}`,
    /** 資材 */
    material: (id: string) => `${DBCollectionPath.Owner.materials()}/${id}`,
    /** メッセージ */
    message: (companyId: string, requestId: string, messageId: string) =>
      `${DBCollectionPath.Owner.messages(companyId, requestId)}/${messageId}`,
    /** 請求 */
    bill: (companyId: string, id: string) =>
      `${DBCollectionPath.Owner.bills(companyId)}/${id}`,
    /** 取下げ理由アンケート */
    closeReasonQuestionnaire: (
      companyId: string,
      projectId: string,
      id: string
    ) =>
      `${DBCollectionPath.Owner.closeReasonQuestionnaires(
        companyId,
        projectId
      )}/${id}`,
    /** アドオンマスター */
    addOnMaster: (id: string) =>
      `${DBCollectionPath.Owner.addOnMasters()}/${id}`,
    /** アドオン */
    addOn: (companyId: string, id: string) =>
      `${DBCollectionPath.Owner.addOns(companyId)}/${id}`,
  },
  Worker: {
    /** ユーザ */
    user: (uid: string) => `${DBCollectionPath.Worker.users()}/${uid}`,
    /** ユーザ（運営用） */
    _user: (uid: string) => `${DBCollectionPath.Worker._users()}/${uid}`,
    /** 作業履歴 */
    workingHistory: (uid: string, projectId: string) =>
      `${DBCollectionPath.Worker.workingHistory(uid)}/${projectId}`,
    /** 作業回数 */
    workCount: (uid: string) =>
      `${DBCollectionPath.Worker.workCounts()}/${uid}`,
    /** 作業回数 */
    notification: (uid: string, id: string) =>
      `${DBCollectionPath.Worker.notification(uid)}/${id}`,
    /** 案件 */
    project: (projectId: string) =>
      `${DBCollectionPath.Worker.projects()}/${projectId}`,
    /** 案件（受注後） */
    _project: (projectId: string) =>
      `${DBCollectionPath.Worker._projects()}/${projectId}`,
    /** 作業内容 */
    work: (projectId: string, workId: string) =>
      `${DBCollectionPath.Worker.works(projectId)}/${workId}`,
    /** 再施工 */
    rework: (projectId: string) =>
      `${DBCollectionPath.Worker.reworks()}/${projectId}`,
    /** 評価 */
    rate: (projectId: string) =>
      `${DBCollectionPath.Worker.rates()}/${projectId}`,
    /** 再施工（詳細） */
    reworkDetail: (projectId: string, reworkId: string) =>
      `${DBCollectionPath.Worker.reworkDetails(projectId)}/${reworkId}`,
    /** 資材 */
    material: (materialId: string) =>
      `${DBCollectionPath.Worker.materials()}/${materialId}`,
    /** 資材 */
    _jobTypes: (jobKey: string) =>
      `${DBCollectionPath.Worker._jobTypes()}/${jobKey}`,
  },
  Remopad: {
    /** ユーザ */
    user: (uid: string) => `${DBCollectionPath.Remopad.users()}/${uid}`,
    /** ユーザ */
    _user: (uid: string) => `${DBCollectionPath.Remopad._users()}/${uid}`,
    /** レシート */
    receipts: (uid: string, receiptId: string) =>
      `${DBCollectionPath.Remopad.receipts(uid)}/${receiptId}`,
    /** 紹介 */
    social: (uid: string) => `${DBCollectionPath.Remopad.social()}/${uid}`,
  },
};

export const TimeZone = "Asia/Tokyo";

/** 正規表現 */
export const RegEx = {
  /** ドメイン */
  Domain: /.*([A-Za-z0-9][A-Za-z0-9\-]{1,61}[A-Za-z0-9]\.)+[A-Za-z]+/,
  /** 正数 */
  PositiveNumber: /^[1-9]\d*$/,
  /** 0含む数値 */
  Number: /^[0-9]\d*$/,
  /** メールアドレス */
  Email:
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
};

export const Validation = {
  isEmail: (v: string) => typeof v === "string" && RegEx.Email.test(v),
};

/** タイムゾーン */
export const TZ = {
  ja: "Asia/Tokyo",
};

/** ユーザ権限 */
export const UserRoles = [
  {
    value: "管理者",
    viewValue: "管理者",
  },
  {
    value: "編集者",
    viewValue: "編集者",
  },
  {
    value: "登録者",
    viewValue: "登録者",
  },
  {
    value: "閲覧者",
    viewValue: "閲覧者",
  },
];

/** 案件ステータス */
export const ProjectStatus = [
  {
    value: "受付中",
    viewValue: "受付中",
  },
  {
    value: "見積中",
    viewValue: "見積中",
  },
  {
    value: "オファー可能",
    viewValue: "発注可",
  },
  {
    value: "オファー中",
    viewValue: "発注済",
  },
  {
    value: "工事中",
    viewValue: "工事中",
  },
  {
    value: "点検中",
    viewValue: "点検中",
  },
  {
    value: "検収待ち",
    viewValue: "検収待ち",
  },
  {
    value: "完了",
    viewValue: "完了",
  },
  {
    value: "取下げ",
    viewValue: "取下げ",
  },
  {
    value: "期限切れ",
    viewValue: "期限切れ",
  },
];

/** 職人ユーザー状態 */
export const WorkerUserStatuses = [
  {
    value: "pending",
    viewValue: "登録承認待ち",
  },
  {
    value: "active",
    viewValue: "承認済",
  },
  {
    value: "banned",
    viewValue: "マッチング不可",
  },
  {
    value: "denied",
    viewValue: "否認",
  },
];

/** 都道府県 */
export const States = [
  {
    value: "大阪府",
    viewValue: "大阪府",
  },
  {
    value: "兵庫県",
    viewValue: "兵庫県",
  },
  {
    value: "京都府",
    viewValue: "京都府",
  },
  {
    value: "東京都",
    viewValue: "東京都",
  },
  {
    value: "神奈川県",
    viewValue: "神奈川県",
  },
  {
    value: "千葉県",
    viewValue: "千葉県",
  },
  {
    value: "埼玉県",
    viewValue: "埼玉県",
  },
];

/** 案件ステータス（特殊） */
export const ProjectSpecialStatus = ["手直し中"];

/** 消費税率（小数表記） */
export const ConsumptionTaxRate = 0.1;

/** システム利用料率（小数表記） */
export const SystemUsageFeeRate = {
  owner: 0.1,
  worker: 0.1,
};

/** システム利用料の最大 */
export const MaxSystemUsageFee = 50000;

/** 案件登録時の最小の引き渡し希望日：X日後 */
export const MinDueDays = 0;

/** 張替工事がある場合の最小の退去立会日：X日後 */
export const MinFinalInspectionBusinessDays = 3;

export const KansaiConfig = {
  FinalInspectionFee: 8000, // 退去立会費用
  ElectricityFee: 1000, // 電気開通手数料
  WaterFee: 2200,
};

export const KantoConfig = {
  FinalInspectionFee: 12000,
  ElectricityFee: 2000,
  WaterFee: 2200,
};

/** 工期調整費 */
export const AdjustmentFee = 3000;
export const WaterFee = 2200;

/** お知らせの固定ドキュメントID */
export const FirstQuestionnaireId = "1stQuestionnaire";

// 都道府県
export const Hokkaido = ["北海道"];
export const Tohoku = [
  "青森県",
  "岩手県",
  "秋田県",
  "宮城県",
  "山形県",
  "福島県",
];
export const Kanto = [
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
];
export const Chubu = [
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
];
export const Kinki = [
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
];
export const Chugoku = ["鳥取県", "島根県", "岡山県", "広島県", "山口県"];
export const Shikoku = ["徳島県", "香川県", "愛媛県", "高知県"];
export const KyushuOkinawa = [
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];
export const Prefs = [
  ...Hokkaido,
  ...Tohoku,
  ...Kanto,
  ...Chubu,
  ...Kinki,
  ...Chugoku,
  ...Shikoku,
  ...KyushuOkinawa,
];

// 会員登録アンケート
// REMODELAを知ったきっかけ
export const SurveyLeads = [
  "弊社営業",
  "新聞・メディア",
  "FAX広告",
  "Web広告",
  "検索エンジン",
  "Twitter",
  "TikTok",
  "Instagram",
  "Facebook",
  "知人の紹介",
  "その他",
];

// 管理戸数カテゴリ
export class ManagedUnitCategory {
  private static to100 = { label: "100戸未満", value: 99 } as const;
  private static to1000 = { label: "100～1000戸", value: 1000 } as const;
  private static to5000 = { label: "1001～5000戸", value: 5000 } as const;
  private static over5000 = { label: "5001戸以上", value: 5001 } as const;

  private static _managedUnitCategories = new Array<ManagedUnitCategory>();

  private constructor(readonly label: string, readonly value: number) {
    ManagedUnitCategory.managedUnitCategories.push(this);
  }

  public static readonly UnitCategoryTo100 = new ManagedUnitCategory(
    ManagedUnitCategory.to100.label,
    ManagedUnitCategory.to100.value
  );
  public static readonly UnitCategoryTo1000 = new ManagedUnitCategory(
    ManagedUnitCategory.to1000.label,
    ManagedUnitCategory.to1000.value
  );
  public static readonly UnitCategoryTo5000 = new ManagedUnitCategory(
    ManagedUnitCategory.to5000.label,
    ManagedUnitCategory.to5000.value
  );
  public static readonly UnitCategoryOver5000 = new ManagedUnitCategory(
    ManagedUnitCategory.over5000.label,
    ManagedUnitCategory.over5000.value
  );

  static get managedUnitCategories(): ManagedUnitCategory[] {
    return this._managedUnitCategories;
  }

  static getLabelByValue(velue: number): string {
    if (velue <= ManagedUnitCategory.to100.value) {
      return ManagedUnitCategory.to100.label;
    }

    if (velue <= ManagedUnitCategory.to1000.value) {
      return ManagedUnitCategory.to1000.label;
    }

    if (velue <= ManagedUnitCategory.to5000.value) {
      return ManagedUnitCategory.to5000.label;
    }

    return ManagedUnitCategory.over5000.label;
  }

  static getValueByLabel(label: string): number {
    const managedUnitCategory = this._managedUnitCategories.find(
      (_managedUnitCategory) => _managedUnitCategory.label === label
    );
    if (managedUnitCategory !== undefined) {
      return managedUnitCategory.value;
    }
    return 0;
  }
}

// 見積の諸経費の行
export const OverheadExpensesRows = {
  SystemUsageFee: {
    idx: 0,
    name: "システム利用料" as const,
  },
  FinalInspectionFee: {
    idx: 1,
    name: "退去立会費" as const,
  },
  AdjustmentFee: {
    idx: 2,
    name: "工期調整費" as const,
  },
  TravelFee: {
    idx: 3,
    name: "交通費" as const,
  },
  MinusFinalInspectionFee: {
    name: "退去立会費減額" as const,
  },
  ElectricityFee: {
    name: "電気開通手数料" as const,
  },
  WaterFee: {
    name: "水道開栓手数料" as const,
  },
};

export const readOnlyOverheadExpensesRowItems = [
  "システム利用料",
  "退去立会費",
  "退去立会費減額",
  "工期調整費",
  "交通費",
  "電気開通手数料",
  "水道開栓手数料",
  "退去立会費お支払済み分",
];

export class EstimateType {
  private static _values = new Array<EstimateType>();

  public static readonly ALL = new EstimateType(
    "all",
    "全面的な項目",
    "全面的な項目の見積がほしい（お見積り完成目安：3～5営業日）"
  );
  public static readonly MIN = new EstimateType(
    "minimum",
    "必要最低限の見積",
    "必要最低限の見積がほしい（お見積り完成目安：2～3営業日）"
  );
  public static readonly ONLY = new EstimateType(
    "only",
    "指定した箇所のみ",
    "指定した箇所のみの見積がほしい"
  );

  private constructor(
    public readonly id: "all" | "minimum" | "only",
    public readonly name: string,
    public readonly label: string
  ) {
    EstimateType._values.push(this);
  }

  static get values() {
    return this._values;
  }

  static get selectValues(): { value: string; viewValue: string }[] {
    return this._values.map((v) => {
      return { value: v.id, viewValue: v.name };
    });
  }

  static get selectValuesForOwner(): { value: string; label: string }[] {
    return this._values.map((v) => {
      return { value: v.id, label: v.label };
    });
  }

  static fromId(id: string): EstimateType {
    return this.values.find((v) => v.id === id) || EstimateType.ALL;
  }

  static isOnly(id: string): boolean {
    return id === EstimateType.ONLY.id;
  }
}

export class WitnessDateStatus {
  private static _values = new Array<WitnessDateStatus>();

  public static readonly DETERMINED = new WitnessDateStatus(
    "determined",
    "入居者様ご希望日時あり"
  );
  public static readonly REQUEST = new WitnessDateStatus(
    "request",
    "日程調整を依頼する"
  );
  public static readonly AFTER = new WitnessDateStatus(
    "after",
    "決まってないので後日連絡する"
  );

  private constructor(
    public readonly id: "determined" | "request" | "after" | "",
    public readonly label: string
  ) {
    WitnessDateStatus._values.push(this);
  }

  static get values() {
    return this._values;
  }

  static get selectValues(): { value: string; viewValue: string }[] {
    return this._values.map((v) => {
      return { value: v.id, viewValue: v.label };
    });
  }

  static get selectValuesForOwner(): { value: string; label: string }[] {
    return this._values.map((v) => {
      return { value: v.id, label: v.label };
    });
  }

  static fromId(id: string): WitnessDateStatus {
    return (
      this.values.find((v) => v.id === id) || new WitnessDateStatus("", "")
    );
  }
}

// 退去立会調整ステータス
export class WitnessAdjustStatus {
  private static _values = new Array<WitnessAdjustStatus>();

  public static readonly ADJUSTMENT = new WitnessAdjustStatus(
    "adjustment",
    "調整中"
  );
  public static readonly WAITING = new WitnessAdjustStatus(
    "waiting",
    "日程連絡待ち"
  );
  public static readonly CONFIRMED = new WitnessAdjustStatus(
    "confirmed",
    "確定"
  );

  private constructor(
    public readonly id: "adjustment" | "waiting" | "confirmed" | "",
    public readonly label: string
  ) {
    if (id !== "") {
      WitnessAdjustStatus._values.push(this);
    }
  }

  static get values() {
    return this._values;
  }

  static get selectValues(): { value: string; label: string }[] {
    return this._values.map((v) => {
      return { value: v.id, label: v.label };
    });
  }

  static fromId(id: string): WitnessAdjustStatus {
    return (
      this.values.find((v) => v.id === id) || new WitnessAdjustStatus("", "")
    );
  }

  isConfirmed(): boolean {
    return WitnessAdjustStatus.CONFIRMED.id === this.id;
  }

  isException(): boolean {
    return !(
      WitnessAdjustStatus.ADJUSTMENT.id === this.id ||
      WitnessAdjustStatus.WAITING.id === this.id ||
      WitnessAdjustStatus.CONFIRMED.id === this.id
    );
  }
}

export class PlaceOfKeyAfterWitness {
  private static _values = new Array<PlaceOfKeyAfterWitness>();

  public static readonly REMODELA = new PlaceOfKeyAfterWitness(
    "remodela",
    "現地にリモデラキーボックスを設置"
  );
  public static readonly RETURN = new PlaceOfKeyAfterWitness("return", "返却");
  public static readonly OTHER = new PlaceOfKeyAfterWitness("other", "その他");

  private constructor(
    public readonly id: "remodela" | "return" | "other" | "",
    public readonly label: string
  ) {
    PlaceOfKeyAfterWitness._values.push(this);
  }

  static get values() {
    return this._values;
  }

  static get selectValues(): { value: string; viewValue: string }[] {
    return this._values.map((v) => {
      return { value: v.id, viewValue: v.label };
    });
  }

  static get selectValuesForOwner(): { value: string; label: string }[] {
    return this._values.map((v) => {
      return { value: v.id, label: v.label };
    });
  }

  static fromId(id: string): PlaceOfKeyAfterWitness {
    return (
      this.values.find((v) => v.id === id) || new PlaceOfKeyAfterWitness("", "")
    );
  }
}

export class PlaceOfKeyAfterWork {
  private static _values = new Array<PlaceOfKeyAfterWork>();

  public static readonly REMODELA = new PlaceOfKeyAfterWork(
    "continue",
    "案内用に継続して設置 ※施主様にて回収後、弊社までご連絡ください"
  );
  public static readonly RETURN = new PlaceOfKeyAfterWork("return", "返却");
  public static readonly OTHER = new PlaceOfKeyAfterWork("other", "その他");

  private constructor(
    public readonly id: "continue" | "return" | "other" | "",
    public readonly label: string
  ) {
    PlaceOfKeyAfterWork._values.push(this);
  }

  static get values() {
    return this._values;
  }

  static get selectValues(): { value: string; viewValue: string }[] {
    return this._values.map((v) => {
      return { value: v.id, viewValue: v.label };
    });
  }

  static get selectValuesForOwner(): { value: string; label: string }[] {
    return this._values.map((v) => {
      return { value: v.id, label: v.label };
    });
  }

  static fromId(id: string): PlaceOfKeyAfterWork {
    return (
      this.values.find((v) => v.id === id) || new PlaceOfKeyAfterWork("", "")
    );
  }
}

export class StatusOfElectricity {
  private static _values = new Array<StatusOfElectricity>();

  public static readonly UNOPENED = new StatusOfElectricity(
    "unopened",
    "未開通"
  );
  public static readonly OPENED = new StatusOfElectricity("opened", "開通済");
  public static readonly REQUEST = new StatusOfElectricity(
    "requestOpening",
    "開通を依頼"
  );

  private constructor(
    public readonly id: "unopened" | "opened" | "requestOpening" | "",
    public readonly label: string
  ) {
    if (id !== "") {
      StatusOfElectricity._values.push(this);
    }
  }

  static get values() {
    return this._values;
  }

  static priceValues(state: string) {
    const { ElectricityFee } = PrefecturesUtils.getConfig(state);
    // 関西 / 関東で電気料金の表示を追加
    const fee = ElectricityFee + ElectricityFee * ConsumptionTaxRate;
    const label = `開通を依頼 ¥${fee.toLocaleString()}（税込）`;
    return [
      StatusOfWater.UNOPENED,
      StatusOfWater.OPENED,
      new StatusOfElectricity(StatusOfElectricity.REQUEST.id, label),
    ];
  }

  static get selectValuesForOwner(): { value: string; label: string }[] {
    return this._values.map((v) => {
      return { value: v.id, label: v.label };
    });
  }

  static fromId(id: string): StatusOfElectricity {
    return (
      this.values.find((v) => v.id === id) ||
      new StatusOfElectricity("", "未設定")
    );
  }

  static isUnopened(id: string): boolean {
    return this.UNOPENED.id === id;
  }

  static isRequest(id: string): boolean {
    return this.REQUEST.id === id;
  }
}

export class StatusOfWater {
  private static _values = new Array<StatusOfWater>();

  public static readonly UNOPENED = new StatusOfWater("unopened", "未開栓");
  public static readonly OPENED = new StatusOfWater("opened", "開栓済");
  public static readonly REQUEST = new StatusOfWater(
    "requestOpening",
    "開栓を依頼 ¥2,420（税込）"
  );

  private constructor(
    public readonly id: "unopened" | "opened" | "requestOpening" | "",
    public readonly label: string
  ) {
    if (id !== "") {
      StatusOfWater._values.push(this);
    }
  }

  static get values() {
    return this._values;
  }

  static get selectValuesForOwner(): { value: string; label: string }[] {
    return this._values.map((v) => {
      return { value: v.id, label: v.label };
    });
  }

  static fromId(id: string): StatusOfWater {
    return (
      this.values.find((v) => v.id === id) || new StatusOfWater("", "未設定")
    );
  }

  static isUnopened(id: string): boolean {
    return this.UNOPENED.id === id;
  }

  static isRequest(id: string): boolean {
    return this.REQUEST.id === id;
  }
}

export class StatusOfConstructionRequest {
  private static _values = new Array<StatusOfConstructionRequest>();

  public static readonly NECESSARY = new StatusOfConstructionRequest(
    "necessary",
    "あり"
  );
  public static readonly UNNECESSARY = new StatusOfConstructionRequest(
    "unnecessary",
    "なし"
  );
  public static readonly UNCLEAR = new StatusOfConstructionRequest(
    "unclear",
    "確認してほしい"
  );
  public static readonly UNKNOWN = new StatusOfConstructionRequest(
    "",
    "未設定"
  );

  private constructor(
    public readonly id: "necessary" | "unnecessary" | "unclear" | "",
    public readonly label: string
  ) {
    if (id !== "") {
      StatusOfConstructionRequest._values.push(this);
    }
  }

  static get selectValues(): { value: string; viewValue: string }[] {
    return this._values.map((v) => {
      return { value: v.id, viewValue: v.label };
    });
  }

  static get selectValuesForOwner(): { value: string; label: string }[] {
    return this._values.map((v) => {
      return { value: v.id, label: v.label };
    });
  }

  static get values() {
    return this._values;
  }

  static fromId(id: string): StatusOfConstructionRequest {
    return (
      this.values.find((v) => v.id === id) ||
      StatusOfConstructionRequest.UNKNOWN
    );
  }

  static isNecessaryById(id: string): boolean {
    return this.NECESSARY.id === id;
  }

  static isUnclearById(id: string): boolean {
    return this.UNCLEAR.id === id;
  }
}

export const OfferPlans = ["standard", "premium"] as const;

export class OwnerRank {
  private static _values = new Array<OwnerRank>();

  public static readonly BRONZE = new OwnerRank("bronze", "ブロンズ", 0.1);
  public static readonly SILVER = new OwnerRank("silver", "シルバー", 0.08);
  public static readonly GOLD = new OwnerRank("gold", "ゴールド", 0.05);
  public static readonly PLATINUM = new OwnerRank("platinum", "プラチナ", 0);
  public static readonly UNKNOWN = new OwnerRank("", "未設定", 0.1);

  private constructor(
    public readonly id: "bronze" | "silver" | "gold" | "platinum" | "",
    public readonly label: string,
    public readonly systemUsageFeeRate: number
  ) {
    if (id !== "") {
      OwnerRank._values.push(this);
    }
  }

  get systemUsagePercent(): number {
    return this.systemUsageFeeRate * 100;
  }

  static get selectValues(): { value: string; label: string }[] {
    return this._values.map((v) => {
      return { value: v.id, label: v.label };
    });
  }

  static get values() {
    return this._values;
  }

  static fromId(id: string): OwnerRank {
    return this.values.find((v) => v.id === id) || OwnerRank.UNKNOWN;
  }

  // 銀以上のランク
  get isSilverAndOver(): boolean {
    return this.systemUsageFeeRate <= OwnerRank.SILVER.systemUsageFeeRate;
  }
}
