import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocaleService {
	constructor() {
	}

	getTimeZone() {
		return 'Asia/Tokyo';
	}
}
