export const environment = {
	production: true,
	hmr: false,
	serviceWorker: false,
	timestampsInSnapshots: true,
	firebase: {
		Admin: {
			apiKey: "AIzaSyAydgcxo0cwK1PwbZ0dOxsOYV8agWWHUXs",
			authDomain: "remodela-adm-furuel.firebaseapp.com",
			databaseURL: "https://remodela-adm-furuel.firebaseio.com",
			projectId: "remodela-adm-furuel",
			storageBucket: "remodela-adm-furuel.appspot.com",
			messagingSenderId: "411950451497",
			functionRegion: "asia-northeast1",
		},
		Owner: {
			apiKey: "AIzaSyAIdO569oWH8lpJt7KN249i6EKJnk7j4nQ",
			authDomain: "remodela-furuel.firebaseapp.com",
			databaseURL: "https://remodela-furuel.firebaseio.com",
			projectId: "remodela-furuel",
			storageBucket: "remodela-furuel.appspot.com",
			storageBucketLifecycleDeleteMiddle:
				"gs://remodela-furuel-lifecycle-delete-middle",
			messagingSenderId: "615434928942",
			functionRegion: "asia-northeast1",
			functionBaseURL:
				"https://asia-northeast1-remodela-furuel.cloudfunctions.net",
		},
		Worker: {
			apiKey: "AIzaSyB_vuSN9pNdyrGQvWK2LgjucEa8mCA_PFs",
			authDomain: "remodela-app-furuel-17589.firebaseapp.com",
			databaseURL: "https://remodela-app-furuel-17589.firebaseio.com",
			projectId: "remodela-app-furuel-17589",
			storageBucket: "remodela-app-furuel-17589.appspot.com",
			messagingSenderId: "726421904144",
			functionRegion: "asia-northeast1",
		},
	},
};
