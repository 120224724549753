import firebase from 'firebase/app';
import Jimp from 'jimp';

export class Storage {
	storage: firebase.storage.Storage

	constructor(storage: firebase.storage.Storage) {
		this.storage = storage
	}

	async uploadCompressedImg(image: string, path: string): Promise<string> {
		return new Promise(async (resolve, reject) => {
			Jimp.read(image).then((image: Jimp) => {
				if (image.bitmap.height > 768) {
					image = image.resize(768, Jimp.AUTO);
				} else if (image.bitmap.width > 1024) {
					image = image.resize(Jimp.AUTO, 1024)
				}

				image.getBuffer(image._originalMime, async (err, stream) => {
					if (err) {
						reject(err);
					}

					const ref = this.storage.ref(path)
					ref.put(stream).then(async () => {
						resolve(await ref.getDownloadURL())
					})
				})
			})
		})
	}

	async exists(path: string): Promise<boolean> {
		try {
			await this.storage.ref(path).getMetadata()
			return true
		} catch (e) {
			return false
		}
	}

	async existsByUrl(url: string): Promise<boolean> {
		try {
			await this.storage.refFromURL(url).getMetadata()
			return true
		} catch (e) {
			return false
		}
	}

	async getDownloadURL(path: string): Promise<string> {
		if (!await this.exists(path)) {
			return ''
		}
		return await this.storage.ref(path).getDownloadURL()
	}

	async removeFile(path: string): Promise<void> {
		if (!await this.exists(path)) {
			return
		}
		await this.storage.ref(path).delete()
	}

	async removeFileByUrl(url: string): Promise<void> {
		if (!await this.existsByUrl(url)) {
			return
		}
		await this.storage.refFromURL(url).delete()
	}
}
