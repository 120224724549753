import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '@services/locale.service';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import momentTz from 'moment-timezone';

const DEFAULT_FORMAT = 'YYYY-MM-DD HH:mm';

@Pipe({ name: 'timestamp' })
export class TimestampPipe implements PipeTransform {
	constructor(
		private localeService: LocaleService,
	) {
	}

	transform(timestamp: string | moment.Moment | FireTimestamp, format?: string): string {
		const TZ = this.localeService.getTimeZone();
		format = format || DEFAULT_FORMAT;

		if (!timestamp) {
			return null;
		}

		if (timestamp instanceof firebase.firestore.Timestamp) {
			return momentTz(timestamp.toDate()).tz(TZ).format(format);
		}

		if (typeof timestamp === 'string') {
			return momentTz(timestamp).tz(TZ).format(format);
		}

		return momentTz(timestamp).tz(TZ).format(format);
	}
}
